<template>
  <div class="calculator-points-miles" style="max-width: 630px; margin: 0 auto;">
    <p class="label mb-1">{{ content.select_conversion_type }}</p>

    <div class="conversion-type d-flex justify-content-center mt-0-5">
      <div :class="`input-wrap ${locale === 'ar-ae' ? 'ml-3' : 'mr-3'}`">
        <input type="radio" name="points-miles" id="miles-to-points" @input="onUpdateConversionType($event)" checked>
        <label for="miles-to-points">{{ content.miles_to_points.label }}</label>
      </div>
      <div class="input-wrap">
        <input type="radio" name="points-miles" id="points-to-miles" @input="onUpdateConversionType($event)">
        <label for="points-to-miles">{{ content.points_to_miles.label }}</label>
      </div>
    </div>

    <div class="calculator mt-3 mt-sm-4">
      <div class="row-1 d-flex">
        <div class="input-wrap col-left">
          <p class="label">{{ content.frequent_flyer_program }}</p>
          <div class="dropdown position-relative">
            <button class="dropdown-toggle d-flex justify-content-between align-items-center w-100" type="button" id="program-select-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span v-html="selectedProgram.title"></span>
              <img src="/images/icon_caret-down.png" class="arrow" alt="">
            </button>
            <div class="dropdown-menu w-100" aria-labelledby="program-select-btn">
              <div class="d-flex flex-column align-items-start">
                <button v-for="(program, index) in selectablePrograms" :key="index" class="option w-100" @click="selectProgram($event)" @keydown.enter="selectProgram($event)" v-html="program.title"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-right text-left d-none d-sm-block">
          <p class="label">{{ content.ratio }}</p>
          <div class="ratio d-flex justify-content-start bg-bonvoy-white">
            <p class="bold">{{ selectedProgram.ratio_display }}</p>
          </div>
        </div>
      </div>

      <div class="row-mobile d-flex d-sm-none mt-2">
        <div class="input-wrap d-flex flex-row align-items-center left-side">
          <label for="value-1" class="label" v-html="content[selectedConversion].input_label"></label>
          <input type="text" id="value-1" class="bold" v-model="rangeSlider" @input="onInput($event)" @blur="onBlur($event)" ref="textInputElMob" disabled>
        </div>
        <div class="d-flex align-items-center right-side">
          <p class="label">{{ content.ratio }}</p>
          <div class="ratio d-flex justify-content-start bg-bonvoy-white">
            <p class="bold">{{ selectedProgram.ratio_display }}</p>
          </div>
        </div>
      </div>

      <div class="row-2 d-flex mt-2 mt-sm-3">
        <div class="input-wrap range col-left">
          <label for="range" class="label d-none d-sm-block" v-html="content[selectedConversion].input_label"></label>
          <div class="range-slider-wrap-el d-flex position-relative w-100" ref="rangeSliderWrapEl">
            <input type="range" v-model="rangeSlider" @input="onInput($event)" ref="rangeSliderEl" id="range" class="w-100" min="0" :max="rangeMax" :step="rangeStep" aria-labelledby="" dir="ltr" disabled>
          </div>
        </div>
        <div class="input-wrap col-right text-left d-none d-sm-block">
          <label for="value-2" class="label" v-html="content[selectedConversion].input_label"></label>
          <input type="text" id="value-2" class="bold" v-model="rangeSlider" @input="onInput($event)" @blur="onBlur($event)" ref="textInputEl" disabled>
        </div>
      </div>

      <div v-if="selectedProgram.bonus && rangeSlider >= 60000" class="row-3 d-flex justify-content-center align-items-center fz-sm mt-3">
        <p class="subtotal bg-bonvoy-white bold text-left m-0">
          = <span class="no-wrap">{{ numberWithCommas(subtotal) }}</span> {{ content[selectedConversion].totals_label }}
        </p>
        <p class="bold ml-1" v-if="rangeSlider >= 60000 && rangeSlider < 120000">
          + <span class="no-wrap">{{ numberWithCommas(selectedProgram.bonus) }}</span> {{ content.bonus }}
        </p>
        <p class="bold ml-1" v-else-if="rangeSlider >= 120000 && rangeSlider < 180000">
          + <span class="no-wrap">{{ numberWithCommas(selectedProgram.bonus * 2) }}</span> {{ content.bonus }}
        </p>
        <p class="bold ml-1" v-else-if="rangeSlider >= 180000 && rangeSlider < 240000">
          + <span class="no-wrap">{{ numberWithCommas(selectedProgram.bonus * 3) }}</span> {{ content.bonus }}
        </p>
        <p class="bold ml-1" v-else-if="rangeSlider >= 240000">
          + <span class="no-wrap">{{ numberWithCommas(selectedProgram.bonus * 4) }}</span> {{ content.bonus }}
        </p>
      </div>

      <div :class="`row-4 ${selectedProgram.bonus ? 'mt-2' : 'mt-3'} mb-4`">
        <p class="total bg-bonvoy-white bold fz-26 w-100" v-html="totalOutput"></p>
      </div>

      <p class="fz-sm mb-3" style="max-width: 550px; margin: 0 auto;" v-html="content.cta_intro"></p>

      <a role="button" :href="content[selectedConversion].cta_href" class="btn-1 custom_click_track" :custom_click_track_value="content[selectedConversion].click_track_value" target="_blank">{{ content.cta_copy }}</a>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { numberWithCommas, numberNoCommas, stripHtml } from '../utilities';

const locale = APP_locale;
const content = APP_content.sec_3.calculator;
const selectedConversion = ref('miles_to_points');
const selectedProgram = ref(content[selectedConversion.value].programs[0]);
const rangeMax = 240000;
const rangeStep = 40;
const rangeResolution = 40000;
const rangeSlider = ref(0);
const rangeSliderWrapEl = ref(null);
const rangeSliderEl = ref(null);
const textInputEl = ref(null);
const textInputElMob = ref(null);
const selectProgramTitle = content[selectedConversion.value].programs[0].title;

function selectProgram(e) {
  const selected = content[selectedConversion.value].programs.filter(program => {
    program.selected = false;
    return stripHtml(program.title) === e.target.textContent;
  });
  selectedProgram.value = selected[0];
  selected[0].selected = true;
  disableEnableInputs();
  nextTick(() => {
    textInputEl.value.value = rangeSlider.value;
    textInputElMob.value.value = rangeSlider.value;
  });
  if (rangeSlider.value < selectedProgram.value.min) {
    rangeSlider.value = selectedProgram.value.min;
    nextTick(() => {
      textInputEl.value.value = selectedProgram.value.min;
      textInputElMob.value.value = selectedProgram.value.min;
    });
  }
  content[selectedConversion.value].input_value = rangeSlider.value;
}

const selectablePrograms = computed(() => {
  const selectable = content[selectedConversion.value].programs.filter(program => {
    return program.title !== selectedProgram.value.title;
  });
  return selectable;
});

const subtotal = computed(() => {
  if (!rangeSlider.value) rangeSlider.value = 0;
  return Math.round(numberNoCommas(rangeSlider.value) / selectedProgram.value.ratio);
});

const totalOutput = computed(() => {
  const totalsLabel = content[selectedConversion.value].totals_label;
  const totalsValueBonus = (multiplier) => `<span class="no-wrap">${numberWithCommas(subtotal.value + selectedProgram.value.bonus * multiplier)}</span>`
  const totalsValue = `<span class="no-wrap">${numberWithCommas(subtotal.value)}</span>`

  if (APP_locale === 'ar-ae') {
    if (rangeSlider.value >= 60000 && rangeSlider.value < 120000) {
      return `${totalsLabel} = ${totalsValueBonus(1)}`;
    } else if (rangeSlider.value >= 120000 && rangeSlider.value < 180000) {
      return `${totalsLabel} = ${totalsValueBonus(2)}`
    } else if (rangeSlider.value >= 180000 && rangeSlider.value < 240000) {
      return `${totalsLabel} = ${totalsValueBonus(3)}`
    } else if (rangeSlider.value >= 240000) {
      return `${totalsLabel} = ${totalsValueBonus(4)}`
    } else {
      return `${totalsLabel} = ${totalsValue}`
    }
  } else {
    if (rangeSlider.value >= 60000 && rangeSlider.value < 120000) {
      return `= ${totalsValueBonus(1)} ${totalsLabel}`;
    } else if (rangeSlider.value >= 120000 && rangeSlider.value < 180000) {
      return `= ${totalsValueBonus(2)} ${totalsLabel}`
    } else if (rangeSlider.value >= 180000 && rangeSlider.value < 240000) {
      return `= ${totalsValueBonus(3)} ${totalsLabel}`
    } else if (rangeSlider.value >= 240000) {
      return `= ${totalsValueBonus(4)} ${totalsLabel}`
    } else {
      return `= ${totalsValue} ${totalsLabel}`
    }
  }
});

function updateConversionType(conversion) {
  selectedConversion.value = conversion;
  const selected = content[conversion].programs.filter((program) => {
    return program.selected;
  });
  selectedProgram.value = selected[0];
  disableEnableInputs();
  nextTick(() => {
    if (textInputEl.value.value < selectedProgram.value.min) {
      rangeSlider.value = selectedProgram.value.min;
      setTimeout(() => {
        textInputEl.value.value = selectedProgram.value.min;
        textInputElMob.value.value = selectedProgram.value.min;
      }, 10);

    } else {
      textInputEl.value.value = rangeSlider.value;
      textInputElMob.value.value = rangeSlider.value;
    }
  });
}

function onUpdateConversionType() {
  if (selectedConversion.value === 'miles_to_points') {
    updateConversionType('points_to_miles');
  } else {
    updateConversionType('miles_to_points');
  }
  rangeSlider.value = content[selectedConversion.value].input_value;
}

function onInput(e) {
  console.log('onInput()');
  // Keeps user from entering non-number characters by stripping them out
  rangeSlider.value = rangeSlider.value.toString().replace(/\D+/, '');

  if (e.target.value > rangeMax) {
    rangeSlider.value = rangeMax;
    textInputEl.value.value = rangeMax;
    textInputElMob.value.value = rangeMax;
  }

  if (e.target.type === 'range') {
    if (e.target.value < selectedProgram.value.min) {
      rangeSlider.value = selectedProgram.value.min;
      textInputEl.value.value = selectedProgram.value.min;
      textInputElMob.value.value = selectedProgram.value.min;
    }
  }

  if (e.target.value === '') {
    nextTick(() => {
      textInputEl.value.value = '';
      textInputElMob.value.value = '';
    })
  }

  content[selectedConversion.value].input_value = rangeSlider.value;
}

function onBlur(e) {
  if (e.target.value === '') {
    rangeSlider.value = selectedProgram.value.min;
  }
  if (e.target.value < selectedProgram.value.min) {
    rangeSlider.value = selectedProgram.value.min;
    textInputEl.value.value = selectedProgram.value.min;
    textInputElMob.value.value = selectedProgram.value.min;
  }
}

function disableEnableInputs() {
  if (selectedProgram.value.title === selectProgramTitle) {
    rangeSliderEl.value.disabled = true;
    textInputEl.value.disabled = true;
    textInputElMob.value.disabled = true;
    rangeSlider.value = selectedProgram.value.min;
    content[selectedConversion.value].input_value = selectedProgram.value.min;
  } else {
    rangeSliderEl.value.disabled = false;
    textInputEl.value.disabled = false;
    textInputElMob.value.disabled = false;
  }
}

function drawRangeSliderMarkers() {
  const markerAmount = rangeMax / rangeResolution + 1;

  for (let i = 0; i < markerAmount; i++) {
    const leftAmount = ((i + 0.085) / (markerAmount - 1)) * 96.9;
    const markerEl = document.createElement('div');
    markerEl.classList.add('marker', `marker-${i + 1}`, 'no-wrap');
    markerEl.style.left = `${leftAmount}%`;

    const markerLabelEl = document.createElement('span');
    if (APP_locale === 'en-us' || APP_locale === 'ar-ae') {
      markerLabelEl.textContent = `${i * rangeStep}${i * rangeStep > 0 ? content.marker_label : ''}`;
    } else {
      markerLabelEl.textContent = numberWithCommas(i * rangeStep * 1000);
    }
    markerEl.appendChild(markerLabelEl);

    if (rangeSliderWrapEl._rawValue) {
      rangeSliderWrapEl._rawValue.appendChild(markerEl);
    }
  }
}

onMounted(() => {
  drawRangeSliderMarkers();
});
</script>
